import type { QueryMatches, TermRecord } from "$lib/datatypes";
import { getSearchParams } from "$lib/searchParams";
import type { PageLoad } from "./$types";
export const prerender = true;

export const load: PageLoad = async ({ url, params, fetch, data }) => {
	let question = getSearchParams(url, 'question', undefined)

	if (question) {
		let r = await fetch(`/query?question=${encodeURIComponent(question)}`)
		let records = await r.json() as TermRecord[]

		return {
			question,
			relatedQuestions: records
		}
	}
	else {
		return { question, ...data }
	}

};
